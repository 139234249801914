
  import {
    Component,
    Vue
  } from 'vue-property-decorator'
  import { Action } from 'vuex-class'


  @Component({
    name: 'RedirectToSubscriptionView'
  })
  export default class AbstractView extends Vue {
  @Action('createSubscriptionPortal', { namespace: 'Licenses' }) createSubscriptionPortal: any;

  mounted() {
    this.createSubscriptionPortal()
    .then((url: string) => 
      window.location.replace(url)
    )
  }
}
